<template>
  <div>
    <div class="min-vh-100 container-box">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">ภาพรวมโปรโมชั่น</h1>
        </b-col>
        <b-col xl="8" class="text-right d-flex justify-content-end">
          <b-button v-b-toggle.sidebar-1 class="btn-filter">
            <font-awesome-icon
              icon="filter"
              title="filter-btn"
              class="text-white mr-0 mr-sm-1"
            />
            <span class="d-none d-sm-inline">ค้นหา</span>
          </b-button>
        </b-col>
      </CRow>

      <div class="mt-3 bg-white p-3">
        <h1 class="header-main">ยอดขายที่มีโปรโมชั่น</h1>
        <b-row no-gutters>
          <b-col md="12">
            <BarChart :xData="chartData.label" :yData="chartData.data" label="โปรโมชั่น" />
          </b-col>
        </b-row>
      </div>

      <div class="mt-3 bg-white p-3 py-sm-0">
        <b-row class="pt-3">
          <b-col md="7">
            <h1 class="header-main">โปรโมชั่น</h1>
            <p class="mb-0">
              Total Revenue: {{ totalRevenue | numeral("0,0") }}
            </p>
          </b-col>
          <b-col md="5" class="text-right"
            ><b-button variant="link" @click="exportFile()">
              นำออกข้อมูล
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div class="mt-3 bg-white">
              <b-row class="no-gutters">
                <b-col>
                  <b-table
                    class="table-list"
                    striped
                    responsive
                    hover
                    :items="items"
                    :fields="fields"
                    :busy="isBusy"
                    show-empty
                    empty-text="ไม่พบข้อมูล"
                  >
                    <template v-slot:cell(sales)="data">
                      <span>{{ data.item.sales | numeral("0,0.00") }}</span>
                    </template>
                    <template v-slot:cell(totalOrder)="data">
                      <span>{{ data.item.totalOrder | numeral("0,0") }}</span>
                    </template>
                    <template v-slot:cell(totalBuyer)="data">
                      <span>{{ data.item.totalBuyer | numeral("0,0") }}</span>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-black my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-sidebar
      id="sidebar-1"
      title="ค้นหาแบบละเอียด"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      ref="filterSidebar"
    >
      <div class="px-3 py-2">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-link px-0"
            @click="onClearFilter()"
          >
            ล้างค่า
          </button>
        </div>

        <label class="label-text">วันเริ่มต้น</label>
        <datetime
          placeholder="กรุณากรอกวันที่"
          class="date-picker mb-3"
          format="dd MMM yyyy"
          v-model="filter.startDate"
        ></datetime>

        <label class="label-text">วันสิ้นสุด</label>
        <datetime
          placeholder="กรุณากรอกวันที่"
          class="date-picker mb-3"
          format="dd MMM yyyy"
          v-model="filter.endDate"
        ></datetime>

        <div class="text-center">
          <p class="text-danger" v-if="errorDate">
            วันสิ้นสุดต้องมากกว่าวันเริ่มต้น
          </p>
        </div>

        <div class="text-center mt-4">
          <button
            type="button"
            class="btn btn-purple button"
            @click="getDataByStatus"
          >
            ค้นหา
          </button>
        </div>
      </div>
    </b-sidebar>
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import axios from "axios";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import BarChart from "./../components/BarChart.vue";

export default {
  components: {
    ModalLoading,
    BarChart,
  },
  data() {
    return {
      statusData: null,
      performanceData: null,
      errorDate: false,
      campaignData: [],
      filter: {
        search: "",
        startDate: null,
        endDate: null,
      },
      chartData: {
        label: [],
        data: [],
      },
      items: [],
      isBusy: false,
      rows: 0,
      fields: [
        {
          key: "promotionName",
          label: "รายละเอียด",
          tdClass: "text-left",
          class: "w-100px",
        },
        {
          key: "totalOrder",
          label: "จำนวนคำสั่งซื้อ",
          class: "w-50px",
        },
        {
          key: "sales",
          label: "ยอดขาย (บาท)",
          class: "w-50px",
        },
        {
          key: "totalBuyer",
          label: "จำนวนผู้ซื้อ",
          class: "w-50px",
        },
      ],
      totalRevenue: 0,
    };
  },
  created: async function () {
    await this.init();
    await this.getAll();
    this.$isLoading = true;
  },
  methods: {
    init() {
      let now = new Date();
      this.filter.endDate = this.$moment(now).format("YYYY-MM-DD");
      this.filter.startDate = this.$moment(now)
        .add(-1, "months")
        .format("YYYY-MM-DD");
    },
    getAll: async function () {
      await this.getDashboardGraphPromotion();
      await this.getDashboardPromotion();
    },
    getDashboardGraphPromotion: async function () {
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Dashboard/getDashboardGraphPromotion`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.chartData.label = [];
        this.chartData.data = [];
        resData.detail.forEach((item, index) => {
          this.chartData.label[index] = item.promotionName
          this.chartData.data[index] = item.sales;
        });
      }
    },
    async getDashboardPromotion() {
      this.$isLoading = false;
      let list = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/dashboard/GetDashboardPromotion`,
        null,
        this.$headers,
        this.filter
      );

      if (list.result === 1) {
        this.items = list.detail;
        this.rows = this.items.length;
        this.totalRevenue = this.items.reduce((a, item) => a + item.sales, 0);
      }

      this.$isLoading = true;
    },
    async getDataByStatus() {
      if (
        this.filter.startDate > this.filter.endDate &&
        this.filter.endDate != ""
      ) {
        this.errorDate = true;
        return;
      }

      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      await this.getAll();
    },
    async onClearFilter() {
      await this.init();
      this.errorDate = false;
      this.$refs.filterSidebar.hide(true);
      await this.getAll();
    },
    exportFile: async function () {
      this.$refs.modalLoading.show();

      let path = "dashboard/ExportDashboardPromotion";
      let filter = this.filter;

      await axios({
        url: `${this.$baseUrl}/api/${path}`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: filter,
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = this.$moment().format("DDMMYYYYhhmmss");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Promotion-${dateExcel}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
      this.$refs.modalLoading.hide();
    },
    handleSearch(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.filter.pageNo = 1;
        this.getAll();
      }, 800);
    },
    btnSearch() {
      this.filter.pageNo = 1;
      this.getAll();
    },
  },
};
</script>

<style scoped>
.dashboard-status-box {
  width: 25%;
  display: inline-block;
}

.dashboard-status {
  border-radius: 5px;
  border: 1px solid #1085ff;
}

.dashboard-status-value {
  color: #1085ff;
  font-size: 20px;
  font-weight: bold;
  width: 25%;
  text-align: right;
}

.performance-box {
  width: 25% !important;
}

.performance-box div,
.campaign-data-box {
  border: 1px solid #dbdbdb;
}

.image {
  width: 100%;
  padding-top: 20%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* ::v-deep .slick-dots {
    padding-bottom: 10px;
  } */
.menuactive {
  color: #ffb300 !important;
}
@media (max-width: 1200px) {
  .performance-box {
    width: 100% !important;
  }

  .dashboard-status-box {
    width: 50%;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 600px) {
  .f-12-mobile {
    font-size: 12px;
  }
}
</style>
